import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'; 

function Message({ text, sender }) {
  const isUser = sender === "user";
  const messageAlignment = isUser ? "flex-end" : "flex-start";
  const messageBackground = isUser ? "#D1E8FF" : "#F1F0F0";
  const messageColor = isUser ? "#000" : "#333";
  const iconColor = isUser ? "#007bff" : "#555";

  const isList = (text) => {
    return text.includes("\n");
  };

  const renderList = (text) => {
    const listItems = text.split("\n").map((item, index) => (
      <li key={index} style={{ margin: "0", padding: "0" }}>
        {item}
      </li>
    ));
    return <ol style={{ listStyleType: "none", margin: "0", padding: "0" }}>{listItems}</ol>;
  };
  
  const messageElement = isList(text) ? renderList(text) : <p style={{ margin: 0 }}>{text}</p>;

  const messageRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const words = text.split(" ");
    controls.start({
      opacity: 1,
    
    });
    scrollToBottom();
  }, [text, controls]);

  const scrollToBottom = () => {
    messageRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  return (
    <div className="svg-icons" style={{ display: "flex", justifyContent: messageAlignment }}>
      {!isUser && (
        <div style={{ margin: "5px" }}>
          <FontAwesomeIcon  style={{ color: iconColor }} />
        </div>
      )}
      <motion.div
        className="message-card"
        style={{
          backgroundColor: messageBackground,
          borderRadius: "10px",
          margin: "5px",
          color: messageColor,
          padding: "8px",
          maxWidth: "70%",
        }}
        ref={messageRef}
        initial={{ opacity: 0 }}
        animate={controls}
      >
        {messageElement}
      </motion.div>
      {isUser && (
        <div style={{ margin: "5px" }}>
          <FontAwesomeIcon style={{ color: iconColor }} />
        </div>
      )}
    </div>
  );
}

export default Message;
