import React, { useState, useRef } from "react";
import Send from '../assets/images/icons/send.png'

import OpenAI from "openai";
const FormData = require("form-data");

const openai = new OpenAI({
  apiKey: "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", // This is also the default, can be omitted
  dangerouslyAllowBrowser: true,
});

function ChatInput({ onSendMessage }) {
  const [text, setText] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);

  let chunks = [];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!text.trim()) return;
    onSendMessage(text);
    setText("");
  };

  const startRecording = async () => {
    setIsRecording(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();
        mediaRecorderRef.current.ondataavailable = (event) => {
          setAudioBlob(event.data);
          chunks.push(event.data);
          console.log("last", event.data);
        };
      } catch (err) {
        console.log("Error accessing the microphone", err);
      }
    } else {
      console.log("Audio recording is not supported in this browser.");
    }
  };

  const stopRecording = async () => {
    setIsRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = (e) => {
        console.log("data available after MediaRecorder.stop() called.");

        setTimeout(() => {
          const blob = new Blob(chunks, { type: "audio/webm" });
          const file = new File([blob], "audio.webm", { type: "audio/webm" });
          chunks = [];
          const audioURL = window.URL.createObjectURL(blob);
          console.log("recorder stopped", blob, file);
        }, 10000);
      };
      // const mp3Data = await mediaRecorderRef.current.getAudioData();
      // const mp3Blob = new Blob([mp3Data], { type: "audio/mp3" });
      // const audioChunks = new Blob(audioBlob, {
      //   type: "audio/mpeg",
      // });
      // console.log("here", mp3Blob);
      console.log("here");
      // const form = new FormData();
      // form.append("file", audio);
      // form.append("model", "whisper-1");
      // const transcription = await openai.audio.transcriptions.create(form);
      // console.log(transcription.text);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="chat-input-area">
      <input
        type="text"
        className="chat-input"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Type your message here..."
      />
      {/* {isRecording ? (
        <button className="record-btn" onClick={stopRecording}>
          🛑
        </button>
      ) : (
        <button className="record-btn" onClick={startRecording}>
          🎙
        </button>
      )} */}
      <button type="submit" className="send-button" disabled={isRecording}>
      <img className="send-img" src={Send}/> 

      </button>
    </form>
  );
}

export default ChatInput;
